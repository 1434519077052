<template>
  <div>
    <a-drawer :title="title" placement="right" width="800" :visible="visible" @close="handleCancel">
      <div>
        <a-row :gutter="48" style="margin-bottom: 20px;">
          <a-col :md="16" :sm="24">
            <a-select
              allow-clear
              mode="multiple"
              label-in-value
              placeholder="选择驻场"
              :value="select"
              style="width: 100%"
              @change="handleChange"
              option-label-prop="label"
            >
              <a-select-option v-for="item in fetch" :key="item.value" :value="item.value" :label="item.name">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="addRow">添加</a-button>
          </a-col>
        </a-row>
        <div class="table-operator">
          <a-button type="danger" icon="delete" @click="deleteRow">移除</a-button>
        </div>
        <a-table
          ref="drawerTable"
          size="default"
          bordered
          :rowKey="record => record.id"
          :columns="columns"
          :data-source="current"
          :rowSelection="rowSelection"
          :loading="loading"
        >
          <span class="myCol" slot="name" slot-scope="text">
            {{ text }}
          </span>

          <span slot="id" slot-scope="text">
            <a-tag color="blue">{{ text }}</a-tag>
          </span>
        </a-table>
      </div>
    </a-drawer>
    <add-resident ref="addModal" :record="record" :residents="select" @reload="reload"></add-resident>
    <delete-resident ref="deleteModal" :record="record" :selectedRows="selectedRows" @reload="delReload"></delete-resident>
  </div>
</template>

<script>
import AddResident from './personModules/AddResident'
import DeleteResident from './personModules/DeleteResident'
import { agentQueryResident, houseAgentQueryResident } from '@/api/agent'
export default {
  name: 'PersonDrawer',
  components: {
    AddResident,
    DeleteResident
  },
  data() {
    return {
      id: null,
      title: '代理商',
      record: null,
      houseId: null,
      houseAgentId: null,
      visible: false,
      select: [],
      fetch: [],
      current: [],
      loading: false,
      columns: [
        {
          title: '驻场',
          dataIndex: 'residentName',
          scopedSlots: { customRender: 'residentName' }
        },
        {
          title: '手机号',
          dataIndex: 'residentPhone',
          scopedSlots: { customRender: 'residentPhone' }
        }
      ],
      // 删除
      selectedRowKeys: [],
      selectedRows: []
    }
  },
  created() {
    this.houseId = this.$route.query.id
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    }
  },
  methods: {
    reload() {
      this.loadData()
    },
    delReload() {
      console.log('del');
      this.selectedRows = []
      this.selectedRowKeys = []
      this.loadData()
    },
    deleteRow() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择驻场人员', 2)
      } else {
        this.$refs.deleteModal.showModal()
      }
    },
    showModal(record) {
      this.id = record.agentId
      this.houseAgentId = record.id
      this.record = record
      this.visible = true
      this.title = record.agentName
      this.loadData()
    },
    handleCancel() {
      this.select = []
      this.visible = false
      this.selectedRowKeys = []
    },
    handleChange(value) {
      this.select = value
    },
    loadData() {
      this.loading = true
      this.select = []
      const param = {
        houseId: this.houseId,
        agentId: this.id,
        ...this.maxPagination
      }
      houseAgentQueryResident(param)
        .then(res => {
          if (res.success) {
            const len = res.data.content.length
            this.current = len === 0 ? [] : res.data.content[0].houseResidentDtoList
            this.loadResident()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadResident() {
      const param = { houseId: this.houseId, agentId: this.id, ...this.maxPagination }
      agentQueryResident(param).then(res => {
        if (res.success) {
          console.log(res, 'all resi')
          const allResident = res.data.content.map(item => {
            return {
              name: item.residentName,
              text: `${item.residentName}(${item.residentPhone})`,
              value: item.residentName + ',' + item.residentId,
              phone: item.residentPhone
            }
          })
          const allId = allResident.map(item => {
            const value = item.value.split(',')
            return value[1]
          })
          const currentId = this.current.map(item => item.residentId)
          const arr = []
          allId.filter((item, index) => {
            if (!currentId.includes(item)) {
              arr.push(allResident[index])
            }
          })
          this.fetch = arr
        }
      })
    },
    addRow() {
      const len = this.select.length
      if (len === 0) {
        this.$message.warning('请选择驻场人员')
      } else {
        this.$refs.addModal.showModal(this.record)
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>

<style lang="less" scoped></style>
