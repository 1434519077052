<template>
  <div>
    <a-modal
      :title="noticeTitle"
      :visible="visible"
      :confirm-loading="loading"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p>确定将这些驻场从代理商“{{ record ? record.agentName : '-' }}”中移除?</p>
    </a-modal>
  </div>
</template>

<script>
import { houseAgentDeleteResident } from '@/api/agent'
export default {
  name: 'DeleteModal',
  props: {
    record: {
      type: Object,
      default: () => {}
    },
    selectedRows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      noticeTitle: '移除代理商驻场',
      visible: false,
      loading: false
    }
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      console.log(this.selectedRows);
      this.loading = true
      const houseResidentIds = this.selectedRows.map(item => {
        return item.houseResidentId
      })
      const param = {
        houseResidentIds
      }
      houseAgentDeleteResident(param)
        .then(res => {
          if (res.success) {
            this.visible = false
            this.$notification.success({
              message: this.noticeTitle,
              description: '成功'
            })
            this.$emit('reload')
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
