import request from '@/utils/request'

const agentApi = {
    agents: '/api/v1/rest/agents',
    query: '/api/v1/agent/query', // 查询代理商
    delete: '/api/v1/agent/delete',

    // 代理商驻场
    addResident: '/api/v1/agentResident/addResident', // 代理商添加驻场
    deleteResident: '/api/v1/agentResident/delete', // 删除代理商下的驻场
    queryResident: '/api/v1/agentResident/query', // 查询代理商的驻场

    // 代理商楼盘
    houseAgentAddResident: '/api/v1/houseResident/addResident', // 楼盘下的代理商添加驻场
    houseAgentDeleteResident: '/api/v1/houseResident/delete', // 删除楼盘下的代理商的驻场
    houseAgentQueryResident: '/api/v1/houseResident/query', // 查询楼盘下的代理商的驻场
    houseAgentDelete: '/api/v1/houseAgent/delete', // 删除楼盘下的代理商
    houseAgentAdd: '/api/v1/houseAgent/addAgent', // 新增楼盘下的代理商
    houseAgentQuery: '/api/v1/houseAgent/query' // 查询楼盘下的代理商
}

export function agents(param) {
    const url = request.requestFormat(agentApi.agents, param)
    return request({
        url,
        method: 'get'
    })
}

export function agentAdd(data) {
    return request({
        url: agentApi.agents,
        method: 'post',
        data
    })
}

export function agentGet(id) {
    return request({
        url: agentApi.agents + `/${id}`,
        method: 'get'
    })
}

export function agentPut(id, data) {
    return request({
        url: agentApi.agents + `/${id}`,
        method: 'put',
        data
    })
}

export function agentEdit(id, data) {
    return request({
        url: agentApi.agents + `/${id}`,
        method: 'patch',
        data
    })
}

export function agentDelete(param) {
    const url = request.requestFormat(agentApi.delete, param)
    return request({
        url,
        method: 'get'
    })
}

export function agentReallyDelete(param) {
    return request({
        url: agentApi.agents + `/${param.id}`,
        method: 'delete'
    })
}

export function agentQuery(param) {
    const url = request.requestFormat(agentApi.query, param)
    return request({
        url,
        method: 'get'
    })
}

// 代理商-驻场
export function agentAddResident(param) {
    const url = request.requestFormat(agentApi.addResident, param)
    return request({
        url,
        method: 'get'
    })
}

export function agentDeleteResident(param) {
    const url = request.requestFormat(agentApi.deleteResident, param)
    return request({
        url,
        method: 'get'
    })
}

export function agentQueryResident(param) {
    const url = request.requestFormat(agentApi.queryResident, param)
    return request({
        url,
        method: 'get'
    })
}

// 楼盘-代理商
export function houseAgentAddResident(data) {
    return request({
        url: agentApi.houseAgentAddResident,
        method: 'post',
        data
    })
}

export function houseAgentDeleteResident(param) {
    const url = request.requestFormat(agentApi.houseAgentDeleteResident, param)
    return request({
        url,
        method: 'get'
    })
}

export function houseAgentQueryResident(data) {
    return request({
        url: agentApi.houseAgentQueryResident,
        method: 'post',
        data
    })
}

export function houseAgentDelete(param) {
    const url = request.requestFormat(agentApi.houseAgentDelete, param)
    return request({
        url,
        method: 'get'
    })
}

export function houseAgentAdd(param) {
    const url = request.requestFormat(agentApi.houseAgentAdd, param)
    return request({
        url,
        method: 'get'
    })
}

export function houseAgentQuery(data) {
    return request({
        url: agentApi.houseAgentQuery,
        method: 'post',
        data
    })
}
